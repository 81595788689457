@font-face {
  font-family: 'bio-sans';
  src: url('https://use.typekit.net/af/d336ac/00000000000000003b9b341e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d336ac/00000000000000003b9b341e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d336ac/00000000000000003b9b341e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'bio-sans';
  src: url('https://use.typekit.net/af/347411/00000000000000003b9b341f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/347411/00000000000000003b9b341f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/347411/00000000000000003b9b341f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'bio-sans';
  src: url('https://use.typekit.net/af/d9a69e/00000000000000003b9b3421/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d9a69e/00000000000000003b9b3421/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d9a69e/00000000000000003b9b3421/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'bio-sans';
  src: url('https://use.typekit.net/af/9c5495/00000000000000003b9b3420/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/9c5495/00000000000000003b9b3420/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/9c5495/00000000000000003b9b3420/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'bio-sans';
  src: url('https://use.typekit.net/af/51460d/00000000000000003b9b3423/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/51460d/00000000000000003b9b3423/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/51460d/00000000000000003b9b3423/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: 'bio-sans';
  src: url('https://use.typekit.net/af/af8cbc/00000000000000003b9b3422/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/af8cbc/00000000000000003b9b3422/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/af8cbc/00000000000000003b9b3422/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'bio-sans';
  src: url('https://use.typekit.net/af/a84958/00000000000000003b9b3425/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/a84958/00000000000000003b9b3425/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/a84958/00000000000000003b9b3425/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'bio-sans';
  src: url('https://use.typekit.net/af/952d18/00000000000000003b9b3424/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/952d18/00000000000000003b9b3424/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/952d18/00000000000000003b9b3424/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'bio-sans';
  src: url('https://use.typekit.net/af/742b39/00000000000000003b9b3426/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/742b39/00000000000000003b9b3426/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('woff'),
    url('https://use.typekit.net/af/742b39/00000000000000003b9b3426/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'bio-sans';
  src: url('https://use.typekit.net/af/865f1e/00000000000000003b9b3427/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/865f1e/00000000000000003b9b3427/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('woff'),
    url('https://use.typekit.net/af/865f1e/00000000000000003b9b3427/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}
